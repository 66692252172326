// Navbar.js
import React from 'react';
import './Navbar.css';

const Navbar = ({ onProductClick, onContactClick }) => {
  return (
    <div className="navbar">
      <ul>
        <li onClick={onProductClick}>Productos</li>
        <li onClick={onContactClick}>Contacto</li>
      </ul>
    </div>
  );
};

export default Navbar;
