// Home.js
import React from 'react';
import Products from './Products';
import Contact from './Contact/Contact';

const Home = () => {
  return (
    <div className="home-container">
      <div id="products" className="page-content">
        <Products/>
      </div>
    </div>
  );
};

export default Home;
