// Header.js
import React from 'react';
import './Header.css';
import Navbar from './Navbar'; // Asegúrate de importar el componente Navbar

const Header = () => {
  return (
    <header className="header-container">
      {/* Logo */}
      <img
        className="logo"
        src={process.env.PUBLIC_URL + '/logo192.png'}
        alt="Logo"
      />

      {/* Nombre "Sinuosus" */}
      <div className="main-text-title">sinuosus</div>
      <Navbar />
    </header>
  );
};

export default Header;
