// Contact.js
import React from 'react';
import './Contact.css';

const Contact = () => {
  return (
    <div id="contact" className="contact-container">
      <div className="contact-content">
        <h1>Contacto</h1>
        <p>Estamos aquí para ayudarte. Contáctanos para obtener más información.</p>
        <div className="contact-details">
          <p>Correo electrónico: info@sinuosus.com</p>
          <p>Teléfono: (123) 456-7890</p>
          <p>Dirección: 123 Calle Principal, Ciudad</p>
        </div>
      </div>
    </div>
  );
};

export default Contact;
