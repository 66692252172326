// Products.js
import React from 'react';

const Products = () => {
  const generateFakeProducts = (count) => {
    const products = [];
    for (let i = 1; i <= count; i++) {
      products.push({
        id: i,
        title: `Producto ${i}`,
        description: `Descripción del Producto ${i}. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
      });
    }
    return products;
  };

  const fakeProducts = generateFakeProducts(10); // Genera 10 productos ficticios

  return (
    <div id="products" className="page-content">
      <h1>Nuestros Productos</h1>
      <p>Explora nuestra colección única de muebles exclusivos.</p>

      {fakeProducts.map((product) => (
        <div className="product" key={product.id}>
          <h2>{product.title}</h2>
          <p>{product.description}</p>
        </div>
      ))}
    </div>
  );
};

export default Products;
