// App.js
import React from 'react';
import Header from './components/Header/Header';
import Home from './pages/Home';
import Contact from './pages/Contact/Contact';
import './App.css';

const App = () => {
  return (
    <div>
      <Header />
      <Home />
      <Contact />
    </div>
  );
};

export default App;
